import React, { useState, useEffect } from 'react';
import AppLayout from '../../components/layout';
import './Location.css';
import List from './comp/List';
import { Spin } from 'antd';
import {
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import {
  fetchYardList,
  addYardLocation,
  EditYardLocation,
} from '../../actions/yardLocationAction';
import Register from "./comp/addLocation"
import axios from 'axios';
import { Routes } from '../../utils/route';
import PageHeader from '../../components/pageHeader/PageHeader';
const Location = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const callback = (val) => {
      if (val === true) {
        setLoading(false);
        props.fetchYardList();
      }
    };
    Routes('manager', callback);
  }, []);
  const getlocation = () => {
    axios.get('https://ipapi.co/json/').then((data) => {
      if (data.data.city) {
        const cityName = data.data.city.toLowerCase();
      }
      // })
    });
  };


  if (!loading) {
    return (
      <AppLayout>
        <PageHeader button={true} title='yard locations' buttonContent={
        <Register
          Button={<><span style={{marginRight:"7px"}}><PlusOutlined /></span><span>add new yard location</span></>}
          title="ADD YARD LOCATION"
          custom={true}
          className="wash_po_add_edit_btn"
          /> }
          />
        <List
        loading={props.loading}
          yardList={props.yardLocationData}
        />
      </AppLayout>
    );
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  yardLocationData: state.yardLocationData.yardLocationData,
  loading:state.yardLocationData.loading
});
export default connect(mapStateToProps, {
  fetchYardList,
  addYardLocation,
  EditYardLocation,
})(Location);
